export const REDUCED_MOTION_COMPONENTS_TYPES = [
	'VideoPlayer',
	'Video',
	'MediaPlayer',
	'MediaControls',
	'MediaOverlayControls',
	'MusicPlayer',
	'AudioPlayer',
	'SlideShowContainer',
	'StripSlideshow',
	'SoundCloudWidget',
	'SlideShowGallery',
]
